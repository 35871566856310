@import "animate.css/animate.css";

$font-size-base: 0.9rem;
$blue: #002C82;
$info: #3B97FB;
@import "~bootstrap/scss/bootstrap";

@import "~glightbox/dist/css/glightbox.css";

body {
  scroll-behavior: smooth;
  font-family: "Noto Sans SC", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-feature-settings: "liga" 0;
  font-variant: no-common-ligatures proportional-nums;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.div-btn {
  height: 40px;
  line-height: 40px;
  position: relative;
  background-color: $info;
  cursor: pointer;
}
.div-btn-with-bubble:before {
  content: "";
  position: absolute;
  left: 100%;
  top: 11px;
  width: 0;
  height: 0;
  border-top: 9px solid transparent;
  border-left: 12px solid $info;
  border-bottom: 9px solid transparent;
}
